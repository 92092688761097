import React from 'react';

export default function Loader({ type = 'dark' }) {
  const color = type === 'light' ? 'bg-white' : 'bg-brand-light';

  return (
    <div className="loader">
      <div className={`bounce1 ${color}`}></div>
      <div className={`bounce2 ${color}`}></div>
      <div className={`bounce3 ${color}`}></div>
    </div>
  );
}
