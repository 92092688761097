import React from 'react';

export default function FluentIcon({
  icon,
  size = 24,
  className,
  spin = false,
}) {
  if (!icon) {
    console.error('No icon defined');
  }

  return (
    <svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={`inline-block ${className ? className : ''} ${
        spin ? 'animate-spin' : ''
      }`}
    >
      {/*<path d={icon} fill={fill || 'currentColor'} />*/}
      {icon}
    </svg>
  );
}
