import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../display/Loader';
import { shallowEqual, useSelector } from 'react-redux';
import FluentIcon from '../fluenticons/FluentIcon';

export default function Button(props) {
  const {
    icon,
    label,
    onClick,
    className,
    style,
    type,
    buttonType,
    loading,
    disabled,
    children,
    rounded = '20px',
    size = 2,
  } = props;

  const TYPES = {
    primary: {
      bgColor: disabled
        ? 'bg-brand-light bg-opacity-50 border-opacity-50'
        : 'bg-brand-light',
      hoverBgColor: disabled
        ? 'bg-brand-dark bg-opacity-50 border-opacity-50'
        : 'bg-brand-dark',
      color: disabled ? 'text-white' : 'text-white',
      borderColor: 'border-brand-base',
    },
    secondary: {
      bgColor: disabled ? 'bg-white' : 'bg-white',
      hoverBgColor: disabled
        ? 'bg-gray-50 bg-opacity-50 border-opacity-50'
        : 'bg-gray-100',
      color: disabled ? 'text-gray-500' : 'text-brand-light',
      borderColor: 'border-brand-light',
    },
    success: {
      bgColor: 'bg-green-500',
      hoverBgColor: 'bg-green-400',
      color: 'text-white',
      borderColor: 'border-green-500',
    },
    normal: {
      bgColor: 'bg-white',
      hoverBgColor: 'bg-gray-100',
      color: 'text-brand-light',
      borderColor: 'border-brand-light',
    },
    external: {
      bgColor: 'bg-white',
      hoverBgColor: 'bg-gray-100',
      color: 'text-black',
      borderColor: 'border-black',
    },
    danger: {
      bgColor: 'bg-red-700',
      hoverBgColor: 'bg-red-500',
      color: 'text-white',
      borderColor: 'border-red-700',
    },
  };

  const { bgColor, color, hoverBgColor, borderColor } =
    TYPES[type || 'primary'];

  return (
    <button
      className={`font-bold ${bgColor} ${
        loading ? bgColor.replace('bg-', 'text-') : color
      }  ${
        disabled ? 'cursor-default' : `hover:${hoverBgColor}`
      } relative border-1 ${borderColor} font-medium rounded-${rounded} transition whitespace-no-wrap  duration-500  p-${size} px-${
        size * 2
      } opacity-100 ${disabled ? '' : 'hover:opacity-75'} ${className || ''}`}
      style={style}
      onClick={
        disabled
          ? (e) => {
              e.preventDefault();
            }
          : onClick
      }
      type={buttonType}
    >
      {icon && (
        <span>
          {icon.prefix ? (
            <FontAwesomeIcon
              icon={icon}
              className={label || children ? `mr-1` : ''}
            />
          ) : (
            <FluentIcon
              icon={icon}
              className={label || children ? `mr-1` : ''}
            />
          )}
        </span>
      )}
      {label || children}
      <div
        className={`absolute top-0 h-full -ml-4 w-full flex flex-row ${
          loading ? '' : 'invisible'
        }`}
      >
        <div className="self-center w-full">
          <Loader type="light" />
        </div>
      </div>
    </button>
  );
}
